.bem_offer {
  display: block;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  text-decoration: none;

  @media (max-width: 997px) {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
    width: auto;
    margin-right: 0;
  }

  &:link {    
    text-decoration: none;
  }

  &:hover {
    border-color: #00b56a;
  }

  &__image,
  &__price,
  &__percent,
  &__age,
  &__get {
    display: inline-block;
    vertical-align: top;
    width: 16%;
    /*padding-top: 18px;*/
    padding-top: 7px;
    font-size: 14px;
  }

  &__price {
    color: #333;
    font-weight: bold;

    @media (max-width: 997px) {
      width: 49%;
      text-align: center;
    }
  }

  &__percent {
    color: #333;
    font-weight: bold;

    @media (max-width: 997px) {
      width: 49%;
      text-align: center;
    }
  }

  &__image {
    padding: 0;
    text-align: center;
    margin-right: 42px;

    img {
      position: relative;
      top:-6px;
      z-index: -1;
    }

    @media (max-width: 997px) {
      display: block;
      width: auto;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__age {
    width: 26%;
    color: #676767;
    font-size: 13px;
    padding-top: 1px;

    @media (max-width: 997px) {
      display: block;
      width: auto;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &__get {
    text-align: right;
    width: 20%;

    @media (max-width: 1025px) {
      width: 19%;
    }

    @media (max-width: 997px) {
      text-align: center;
      display: block;
      width: auto;
    }
  }
}