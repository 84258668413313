$parallelogram-bg: #FF4242;
$parallelogram-text: #fff;
.parallelogram {
  background: $parallelogram-bg;
  color: $parallelogram-text;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  padding: 0 6px;
  min-width: 126px;

  &,
  &__left-angle,
  &__right-angle {
    display: inline-block;
    vertical-align: bottom;
    height: 14px;
  }

  &__left-angle {
    border-left: solid transparent 7px;
    border-bottom: solid $parallelogram-bg 14px;
    margin-left: -7px;

    /*@media (max-width: 400px) {
      display: none;
    }*/
  }

  &__right-angle {
    border-right: solid transparent 7px;
    border-top: solid $parallelogram-bg 14px;
    margin-right: -7px;


    /*@media (max-width: 400px) {
      display: none;
    }*/
  }

  &__wrapper {
    line-height: 1;
    min-height: 18px;
    white-space: nowrap;
    margin-top: -20px;
    margin-bottom: -8px;
  }
}